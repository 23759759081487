import React, { useEffect } from 'react';

const Index = () => {
  useEffect(() => {
    document.title = "珠宝区块链证书";
  }, []);
  return (
    <div>
      <img style={{ width: '100%' }} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/demo/Lark20210601-143425.jpg" alt=""/>
    </div>
  )
}

export default Index;